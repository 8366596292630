import { Builder } from '@builder.io/react'
import CdnImage from 'components/util/CdnImage'
// import CloudinaryImage from 'components/util/CloudinaryImage'
import { HeadingClass, HeadingType } from 'enums'
import React from 'react'
import CustomHeading from '../common/heading'

interface Logo {
  imgSrc: any
  alt: string
  cloudinaryLogoImage: any
  builderLogoImage: any
}

interface Props {
  backgroundImage: any
  backgroundColor: string
  textColor: string
  logos: Logo[]
  headingText: string
  isHeadingEnabled: boolean
  headingType: HeadingType
  headingClass: HeadingClass
}

const LogoCloudAlternate = ({
  backgroundImage = '',
  backgroundColor = '',
  logos,
  textColor,
  headingText,
  isHeadingEnabled,
  headingType,
  headingClass
}: Props) => {
  const getLogos = () => {
    return logos.map((logo, i) => (
      <div
        className="flex-shrink-1 mt-4 flex max-h-4 w-1/4 justify-center sm:w-1/6 lg:max-h-8 lg:flex-shrink-0 lg:flex-grow-0"
        key={i}
      >
        <CdnImage
          className="object-contain pr-1 pl-1 sm:pr-0 sm:pl-0"
          url={logo.builderLogoImage}
          alt={logo.alt}
          steps={[150, 205]}
          renderPlaceholder={false}
          style={{
            width: '1680px',
            height: '505px',
            maxHeight: '100%',
            maxWidth: '100%'
          }}
        />

        {/* {logo.builderLogoImage ? (
          <CdnImage
            className="object-contain pr-1 pl-1 sm:pr-0 sm:pl-0"
            url={logo.builderLogoImage}
            alt={logo.alt}
            steps={[150, 205]}
            renderPlaceholder={false}
            style={{
              width: '1680px',
              height: '505px',
              maxHeight: '100%',
              maxWidth: '100%'
            }}
          />
        ) : (
          <CloudinaryImage
            className="object-contain pr-1 pl-1 sm:pr-0 sm:pl-0"
            publicId={logo.cloudinaryLogoImage?.public_id}
            alt={logo.alt}
            steps={[150, 205]}
            autoFormat={false}
            renderPlaceholder={false}
            style={{
              width: '1680px',
              height: '505px',
              maxHeight: '100%',
              maxWidth: '100%'
            }}
          />
        )} */}
      </div>
    ))
  }

  const getHeading = () => {
    if (!isHeadingEnabled) return null
    return (
      <CustomHeading
        classList="font-bold text-white text-center mt-0 md:mt-3"
        headingClass={headingClass}
        headingType={headingType}
        color={textColor}
      >
        {headingText}
      </CustomHeading>
    )
  }

  return (
    <div
      className="bg-cover"
      style={{ backgroundColor, backgroundImage: backgroundImage ? `url(${backgroundImage ?? ''})` : 'none' }}
    >
      {/* Desktop view */}
      <div className="block md:hidden mx-auto max-w-7xl py-3 px-4 sm:py-10 sm:px-6 lg:px-6">
        {getHeading()}
        <div className="flow-root sm:mt-8 lg:mt-10">
          <div className="flex flex-wrap justify-center gap-x-0 sm:gap-x-12">{getLogos()}</div>
        </div>
      </div>
      {/* Mobile view */}
      <div className="mx-auto max-w-7xl py-3 px-4 sm:py-10 sm:px-6 lg:px-6 md:flex md:flex-row hidden ">
        <div className="flow-root sm:mt-8 lg:mt-10">
          <div className="flex flex-wrap justify-center gap-x-0 sm:gap-x-12 align-center">
            {' '}
            {getHeading()}
            {getLogos()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogoCloudAlternate
